// import { AlertWithIcon, InfoAlert } from "../../../../../components/Alert/Alert";
import { useMutationUserPaytmPay, useMutationUserPaytmProcess, typePaytmProcessPayload } from "../../../../../api/paytm";
import { useMutationUserPhonePePay } from "../../../../../api/phonepe";
import { useMutationUserEasebuzzPay, useMutationUserEasebuzzProcess } from "../../../../../api/easebuzz";
import PaytmCheckOut from "../../../../../helper/Paytm";
import EaseBuzzCheckOut from "../../../../../helper/EaseBuzz";
import { useState } from "react";
import queryClient from "../../../../../context/query";
import Loader from "../../../../../components/Loader/Loader";
import CustomAlert from "../../../../../helper/Alerts";

import AdsComponent from '../../../../../components/GoogleAds/GoogleAds';
import { Card, Form } from "react-bootstrap";

import GoogleCaptchaV3 from "../../../../../components/Captcha/google";

type typePaymentConfigHistory = {
    [key: number]: {
        config: any;
        createdAt: number;
    }
}


function PublicDashboardPaymentGateway() {
    const paytmCheckout = PaytmCheckOut();
    const checkoutJsInstance = paytmCheckout.checkoutJsInstance;

    const easeBuzzCheckout = EaseBuzzCheckOut();
    const easeBuzzInstance = easeBuzzCheckout.checkoutJsInstance;

    const { mutateAsync: paytmMutate, isLoading: paytmIsLoading } = useMutationUserPaytmPay();
    const { mutateAsync: phonePeMurate, isLoading: phonePeIsLoading } = useMutationUserPhonePePay();
    const { mutateAsync: easeBuzzMutate, isLoading: easeBuzzIsLoading } = useMutationUserEasebuzzPay();
    const { mutateAsync: easeBuzzProcess } = useMutationUserEasebuzzProcess();

    const processPayment = useMutationUserPaytmProcess();
    const captcha = GoogleCaptchaV3();

    const [currentAmount, setCurrentAmount] = useState<number>(22);
    const [paymentConfigHistory, setPaymentConfigHistory] = useState<typePaymentConfigHistory>({});

    const [phonePePaymentConfigHistory, setPhonePePaymentConfigHistory] = useState<typePaymentConfigHistory>({});
    const [easeBuzzPaymentConfigHistory, setEaseBuzzPaymentConfigHistory] = useState<typePaymentConfigHistory>({});

    async function getPaytmPaymentConfig(amount: number) {
        console.log(paymentConfigHistory)

        if (paymentConfigHistory[amount] && (Date.now() - paymentConfigHistory[amount].createdAt) < 1000 * 60 * 15) {
            return paymentConfigHistory[amount].config;
        } else {
            const payload = await paytmMutate({ amount: amount });
            setPaymentConfigHistory(
                {
                    ...paymentConfigHistory,
                    [amount]: {
                        config: payload,
                        createdAt: Date.now()
                    }
                }
            )
            return payload;
        }
    }

    async function getPhonePePaymentConfig(amount: number) {
        console.log(phonePePaymentConfigHistory)

        const whitelistedhost = ["localhost:9000", "idcard.store"];
        if (!whitelistedhost.includes(window.location.host)) {
            CustomAlert.error("Error", "PhonePe Payment Gateway is not available on this domain. please use the idcard.store domain.");
            return;
        }

        if (phonePePaymentConfigHistory[amount] && (Date.now() - phonePePaymentConfigHistory[amount].createdAt) < 1000 * 60 * 15) {
            return phonePePaymentConfigHistory[amount].config;
        } else {
            let captcha_response = await captcha.sureChanllenge("phonepe");

            const payload = await phonePeMurate({ amount: amount, captcha: captcha_response || ""});
            setPhonePePaymentConfigHistory(
                {
                    ...phonePePaymentConfigHistory,
                    [amount]: {
                        config: payload,
                        createdAt: Date.now()
                    }
                }
            )
            return payload;
        }
    }

    async function getEaseBuzzPaymentConfig(amount: number) {
        console.log(easeBuzzPaymentConfigHistory)

        const whitelistedhost = ["localhost:9000", "idcard.store"];
        if (!whitelistedhost.includes(window.location.host)) {
            CustomAlert.error("Error", "EaseBuzz Payment Gateway is not available on this domain. please use the idcard.store domain.");
            return;
        }

        if (easeBuzzPaymentConfigHistory[amount] && (Date.now() - easeBuzzPaymentConfigHistory[amount].createdAt) < 1000 * 60 * 15) {
            return easeBuzzPaymentConfigHistory[amount].config;
        } else {
            let captcha_response = await captcha.sureChanllenge("easebuzz");

            const payload = await easeBuzzMutate({ amount: amount, captcha: captcha_response || ""});
            setEaseBuzzPaymentConfigHistory(
                {
                    ...easeBuzzPaymentConfigHistory,
                    [amount]: {
                        config: payload,
                        createdAt: Date.now()
                    }
                }
            )
            return payload;
        }
    }

    let config = {}
    config["style"] = {
        "bodyBackgroundColor": "#FFFFFF",
        "bodyColor": "#000000",
        "themeBackgroundColor": "#00b9f5",
        "themeColor": "#FFFFFF",
        "headerBackgroundColor": "#b8e986",
        "headerColor": "#000000",
        "borderColor": "#cee5e0",
        "errorColor": "",
        "successColor": "",
        "gradient": {
            "degree": 90,
            "direction": "",
            "buttonGradientsColors": [],
            "headerGradientsColors": []
        },
        "label": "Dark theme",
        "name": "DARK"
    }
    config["handler"] = {
        transactionStatus: async function (data: typePaytmProcessPayload) {
            checkoutJsInstance.close();
            CustomAlert.loading(null, null, null);
            setPaymentConfigHistory(prev => {
                delete prev[currentAmount];
                return prev;
            })
            await processPayment.mutateAsync(data);

            queryClient.invalidateQueries("userStats");

        },
        notifyMerchant: function notifyMerchant(eventName, data) {
            console.log("notify merchant about the payment state", eventName, data);
        }
    }


    async function AddMoneyPaytm() {
        console.log(checkoutJsInstance)
        if (!checkoutJsInstance.init) return;
        if (!currentAmount) return;

        const payload = await getPaytmPaymentConfig(currentAmount);
        config = {
            ...config, ...payload
        }
        checkoutJsInstance.init(config).then(function onSuccess() {
            checkoutJsInstance.invoke();
        }).catch(function onError(error) {
            console.log("error => ", error);
        });
    }


    async function AddMoneyPhonePe() {
        if (!currentAmount) return;
        const payload = await getPhonePePaymentConfig(currentAmount * 100);

        console.log(payload, "PAYLOAD")

        if (payload.link) {
            window.open(payload.link, "_blank");
        }

    }


    async function AddMoneyEaseBuzz() {
        if (!easeBuzzInstance.initiatePayment) return;
        if (!currentAmount) return;

        const payload = await getEaseBuzzPaymentConfig(currentAmount);

        async function onResponse(response: any) {
            console.log(response);
            if (response.error == true) {
                // CustomAlert.loading(null, null, null);
                console.log("Error", response.message)

            } else {
                console.log("txnid", response.txnid)
                
                let captcha_response = await captcha.sureChanllenge("easebuzz_payment_process");

                const payload = {
                    order_id: response.txnid,
                    captcha: captcha_response || null
                }

                if (!response.txnid) {
                    return CustomAlert.error("Error", response.status);
                }
                
                try {
                    await easeBuzzProcess(payload);
                    queryClient.invalidateQueries("userStats");
                } catch (error) {
                    console.log(error)
                }

            }
            
            setEaseBuzzPaymentConfigHistory(prev => {
                delete prev[currentAmount];
                return prev;
            })

        }

        const config = {
            access_key: payload.data,
            onResponse: onResponse,
            theme: "#123456"
        }
        easeBuzzInstance.initiatePayment(config);
    }

    // if (paytmCheckout.isLoading) {
    //     return <Loader middle={true} />
    // }

    if (easeBuzzCheckout.isLoading) {
        return <Loader middle={true} />
    }

    return (
        <>

            {/* <InfoAlert title={"Payment Notice - There is no change in pricing"} content={
                [
                    `English: We've updated our pricing so that now, no matter the recharge amount, you'll receive the same value. Previously, for a 22 rupee recharge, 
                you could make 10 cards, costing 2.2 rupees per card. With our new pricing, a 22 rupee recharge still allows you to make 10 cards at the 
                same rate of 2.2 rupees per card.`,
                    `Hindi: हमने अपना मूल्य निर्धारण अपडेट कर दिया है ताकि अब, रिचार्ज राशि चाहे जो भी हो, आपको वही मूल्य प्राप्त होगा। पहले, 22 रुपये के रिचार्ज पर आप 10 कार्ड बना सकते थे, 
                जिसकी लागत प्रति कार्ड 2.2 रुपये थी। हमारी नई कीमत के साथ, 22 रुपये का रिचार्ज अभी भी आपको 2.2 रुपये प्रति कार्ड की समान दर पर 10 कार्ड बनाने की अनुमति देता है।`
                ]
            } close={false} />

            <AlertWithIcon title={"Payment Notice"} description={"Please Use UPI for Payments."} /> */}


            <div className="row g-4">


                <div className={"col-md-6 col-sm-6 col-lg-6 mx-auto"}>
                    <Card>
                        <Card.Header className="border-bottom">
                            <Card.Title className="mb-0">Plans</Card.Title>
                            <p className="mb-0 small">Purchase a plan to get started</p>
                        </Card.Header>
                        <Card.Body>

                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Plan <span className="text-danger">*</span></Form.Label>
                                    <Form.Select aria-label="Default select example" defaultValue={22} name="amount" required onChange={(e) => setCurrentAmount(parseInt(e.target.value))}>
                                        <option value="1" className="hidden_feature" style={{display: "none"}} > ₹ 1 Plan - 0 Cards</option>
                                        <option value="22"> ₹ 22 Plan - 10 Cards</option>
                                        <option value="55"> ₹ 55 Plan - 25 Cards</option>
                                        <option value="110"> ₹ 110 Plan - 50 Cards</option>
                                        <option value="220"> ₹ 220 Plan - 100 Cards</option>
                                        <option value="385"> ₹ 385 Plan - 175 Cards</option>
                                        <option value="550"> ₹ 550 Plan - 250 Cards</option>
                                        <option value="1100"> ₹ 1100 Plan - 500 Cards</option>
                                    </Form.Select>
                                </Form.Group>


                                {/* pay button */}
                                <div className="d-grid mt-4">
                                    <button type="button" className="btn btn-dark d-flex justify-content-center align-items-center" 
                                        disabled={paytmIsLoading || true || captcha.isLoading} onClick={AddMoneyPaytm}>


                                        <span className="fs-5 me-2">Pay with</span>
                                        <img src="/static/assets/images/paytm_trans.png" className="h-20px h-md-20px" alt="Paytm" />

                                    </button>

                                    <button type="button" className="btn btn-dark d-flex justify-content-center align-items-center" 
                                            disabled={phonePeIsLoading || captcha.isLoading} onClick={AddMoneyPhonePe}>
                                        <span className="fs-5 me-2">Pay with</span>
                                        <img src="/static/assets/images/phonepe_trans.png" className="h-30px h-md-30px" alt="PhonePe" />
                                        
                                    </button>

                                    <button type="button" className="btn btn-dark d-flex justify-content-center align-items-center" 
                                            disabled={easeBuzzIsLoading || captcha.isLoading} onClick={AddMoneyEaseBuzz}>
                                        <span className="fs-5 me-2"> ✨ Pay with</span>
                                        <img src="/static/assets/images/easebuzz.png" className="h-30px h-md-30px" alt="PhonePe" />
                                        
                                    </button>

                                </div>
                            </Form>

                        </Card.Body>
                    </Card>
                </div>
            </div>


            <div className="row g-4 mt-4">
                <div className="col-12">
                    <AdsComponent dataAdSlot="5824067931" />
                </div>
            </div>

        </>
    )
}

export default PublicDashboardPaymentGateway;