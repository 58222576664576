import PublicDashboardTitle from "../Components/Title";
import { PublicDashboardIframeHtml } from "../Components/PageElements";
import { useState, useEffect } from "react";

import {useMutationSendForm} from "../../../../api/formdata";

import debounce from 'debounce-promise';
import AsyncSelect from 'react-select/async';
import axios from "axios";
import AdsComponent from "../../../../components/GoogleAds/GoogleAds";
import {convertBase64ToURL} from "../../../../helper/Decoder";

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const DEFAULT_PAYLOAD = {
    gender: "male",
    lang: "english",
    name: "",
    day: 1,
    month: 1,
    year: 2021,
    hour: 0,
    min: 0,
    sec: 0,

    place: "",
    tzone: "",
    lat: "",
    lon: "",
}

type typePayload = {
    name: string,
    gender: string,
    day: number,
    month: number,
    year: number,
    hour: number,
    min: number,
    sec: number,
    lang: string,

    place: string,
    tzone: string,

    lat: string,
    lon: string,
}

function PublicDashboardKundli() {
    const [payload, setPayload] = useState<typePayload>(DEFAULT_PAYLOAD);

    const loadOptions = debounce(async (inputValue: any, callback: any) => {
        const jk = await axios.get(`https://kundli.amd64.workers.dev/AstroChat/cities/allcountries/autocomplete?limit=10&key=${inputValue}`, {
            headers: {"Accept": "application/json", "Content-Type": "application/json"}
        }).then((res) => {
            let jk: any[] = [];
            res.data.data.map((item) => {
                jk.push({ value: item, label: `${item.name}, ${item.state}, ${item.countryCode}` })
            })
            return jk;
        })
        callback(jk);
    }, 500)

    function onSelection(inputValue: any) {
        console.log(inputValue);
        setPayload({ ...payload, place: inputValue.label, tzone: inputValue.value.timezoneOffset, lat: inputValue.value.latitude, lon: inputValue.value.longitude })
    }

    function onChange(inputValue: any) {
        if (inputValue.target.name) {
            setPayload({ ...payload, [inputValue.target.name]: inputValue.target.value})
        }
    }

    const [output, setOutput] = useState({
        html: "",
    })

    const { mutate, isLoading, data } = useMutationSendForm();

    useEffect(() => {
        if (data) {
            setOutput(convertBase64ToURL(data) as any);
            console.log(convertBase64ToURL(data));
        }
    }, [data])

    function onSubmit(e) {
        e.preventDefault();
        let formdata = new FormData();
        for(let [key, value] of Object.entries(payload)) {
            formdata.append(key, String(value));
        }
        mutate({
            endpoint: "/other/make_kundli",
            form: formdata
        })

        console.log(e.target);
    }

    return (
        <>
            <PublicDashboardTitle title={"Kundli"} icon={"fa-solid fa-meteor fa-fw me-1"} addcardbtn={false} />
            <div className="row g-4">
                <div className="col-md-6">
                    <div className="card border">
                        <div className="card-header align-items-center border-bottom p-4">
                            <h4 className="mb-0 text-center">Make Kundli</h4>
                        </div>
                        
                        {/* <!-- Card body START --> */}
                        <div className="card-body">
                            <form onChange={onChange} onSubmit={onSubmit}>

                                <div className="row mb-3">
                                    <div className="mb-3 col-6">
                                        <label className="form-label">Name <span className="text-danger">*</span></label>
                                        <input type="text" name="name" className="form-control" placeholder="Name" required/>
                                    </div>
                                    <div className="mb-3 col-6">
                                        <label className="form-label">Gender <span className="text-danger">*</span></label>
                                        <select className="form-select" name="gender" defaultValue={payload.gender} required>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                </div>

                                
                                <div className="row mb-3">
                                    {
                                        [["day", range(1, 31, 1)], ["month", range(1, 12, 1)], ["year", range(2023, 1900, -1)], 
                                        ["hour", range(0, 23, 1)], ["min", range(0, 59, 1)], ["sec", range(0, 59, 1)]].map((item: any[], index) => {
                                            return (
                                                <div className="mb-3 col-4" key={index}>
                                                    <label className="form-label text-capitalize">{item[0]} <span className="text-danger">*</span></label>
                                                    <select className="form-select" name={item[0]} required>
                                                        {
                                                            item[1].map((item2, index2) => {
                                                                return (
                                                                    <option value={item2} key={index2}>{item2}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="row mb-3">
                                    <div className="mb-3 col">
                                        <label className="form-label">Langauge <span className="text-danger">*</span></label>
                                        <select className="form-select" name="lang" defaultValue={payload.lang} required>
                                            <option value="1">English</option>
                                            <option value="2">Hindi</option>
                                        </select>
                                    </div>
                                </div>



                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <label className="form-label">Select City <span className="text-danger">*</span></label>
                                        <AsyncSelect loadOptions={loadOptions} name="city" onChange={onSelection} required/>
                                    </div>
                                </div>

                                <div className="card-footer border-top">
                                    <div className="text-center mt-3">
                                        <button className="btn btn-primary fs-5">
                                            <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                            <span role="status">Submit</span>
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                        {/* <!-- Card body END --> */}
                    </div>
                </div>

                <PublicDashboardIframeHtml title="Kundli Preview" description="" src={output.html || undefined} />


                <div className="col-12">
                    <AdsComponent dataAdSlot="1381001173" />
                </div>

            </div>
        </>
    )
}

export default PublicDashboardKundli;