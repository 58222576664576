const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays: Uint8Array[] = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {
      type: contentType,
    });
  
    return blob;
};


type typeBase64ToURL = {
    [key: string]: string | boolean | undefined
}

const convertBase64ToURL = (data : typeBase64ToURL) => {
    // if data if string else skip
    
    const output: typeBase64ToURL = {};
    for (const [key, value] of Object.entries(data)) {
        if (typeof value === "string") {
            if (key === "a4_img") {
                output[key] = URL.createObjectURL(b64toBlob(value, "image/png")) || undefined;
            } else if  (key === "a4") {
                output[key] = URL.createObjectURL(b64toBlob(value, "application/pdf")) || undefined;
            } else if  (key === "html") {
                output[key] = URL.createObjectURL(b64toBlob(value, "text/html")) || undefined;
            } else {
                output[key] = URL.createObjectURL(b64toBlob(value, "image/png")) || undefined;
            }
        } else {
            output[key] = value;
        }
    }
    return output;
}


export { b64toBlob, convertBase64ToURL}
