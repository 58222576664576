import PublicDashboardTitle from "../Components/Title";
import {PublicDashboardIframe} from "../Components/PageElements";
import {useMutationSendForm} from "../../../../api/formdata";
import {useEffect} from "react";

import { getOutputState } from "../../../../helper/Main";
import { TypeOutput } from "../../../../api/types";

import {convertBase64ToURL} from "../../../../helper/Decoder";
import AdsComponent from "../../../../components/GoogleAds/GoogleAds";

import { CardNotification } from "../../../../components/Alert/Alert";

function PublicDashboardPassporSizePhoto() {
    const [output, setOutput] = getOutputState();

    const { mutate, isLoading, data } = useMutationSendForm();

    useEffect(() => {
        if (data) {
            setOutput(convertBase64ToURL(data) as TypeOutput);
            console.log(convertBase64ToURL(data));
        }
    }, [data])

    function onSubmit(e: any) {
        e.preventDefault();
        let formdata = new FormData(e.target);

        for (const [key, value] of Array(...formdata.entries())) {
            if (value instanceof File && !value.name) {
                formdata.delete(key);
            }
        }

        mutate({
            endpoint: "/other/make_passport_size_photo",
            form: formdata
        })
    }


    return (
        <>
            <PublicDashboardTitle title={"Passport Size Photo"} icon={"bi bi-camera fa-fw me-1"} addcardbtn={true} />
            
            {
                <CardNotification 
                    title={"Information"} 
                    message={"Hindi: फोटो अपलोड करने से पहले, फोटो का आकार बदलें 1.3 x 1.7 या 390 x 510 पिक्सेल | </br> English: Before uploading the photo, resize the photo to 1.3 x 1.7 or 390 x 510 pixels."} 
                    sample_files={[]}
                />
            }

            <div className="row g-4">
                <div className="col-md-6">
                    <div className="card border">
                        <div className="card-header border-bottom">
                            <h5 className="card-header-title">PASSPORT SIZE PHOTO</h5>
                            <p className="mb-0 small">Make passport size photos in one click.</p>
                            
                            <ul className="nav nav-pills nav-pills-primary-soft mt-2 d-flex justify-content-center" id="tour-pills-tab" role="tablist">
                                <li className="nav-item me-2">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#single">Single</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#multiple">Multiple</a>
                                </li>
                            </ul>
                        </div>

                        {/* <!-- Card body START --> */}

                        <div className="tab-content">
                            <div className="tab-pane show active" id="single">
                                <form onSubmit={onSubmit}>
                                    <div className="card-body">
                                        <div className="input-group mb-3 ">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg" required/>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <input type="text" name="name" className="form-control" placeholder="Name"/>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Date</label>
                                            <input type="text" name="date" className="form-control" placeholder="dd-mm-yyyy"/>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Number <span className="text-danger">*</span></label>
                                            <input type="number" name="qt" className="form-control" placeholder="Images range 1 to 30" min="1" max="42" required/>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Page Size <span className="text-danger">*</span></label>
                                            <select className="form-select js-choice" name="page" required defaultValue={"a4-5x6"}>
                                                <option value="4x6-3x4">4x6 page (12 photos)</option>
                                                <option value="4x6-4x2">4x6 page (8 photos)</option>
                                                <option value="a4-5x6">A4-5x6 page (30 photos)</option>
                                                <option value="a4-6x7">A4-6x7 page (42 photos)</option>
                                            </select>
                                        </div>


                                        <div className="mb-3">
                                            <label className="form-label">Change Background</label>
                                            <select id="" className="form-select js-choice" name="cb" required>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Expand Image (Recommend No)</label>
                                            <select id="" className="form-select js-choice" name="expand" required>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>

                                        <div className="mb-3 col-sm-6 col-md-4">
                                            <label className="form-label">Background Color</label>
                                            <input type="color" className="form-control" name="bg" placeholder="Select color" style={{height: "45px"}} defaultValue="#ffffff"/>
                                        </div>
                                    </div>

                                    <div className="card-footer border-top">
                                        <div className="text-center mt-3">
                                            <button className="btn btn-primary fs-5">
                                                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                                <span role="status">Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                            <div className="tab-pane" id="multiple">
                                <form onSubmit={onSubmit}>
                                    <div className="card-body">
                                        <div className="input-group mb-3">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg" required/>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file1" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg"/>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file2" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg"/>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file3" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg"/>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file4" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg"/>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file5" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg"/>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="input-group-text d-none d-sm-block" htmlFor="inputGroupFile01">Select Photo</label>
                                            <input type="file" name="file6" className="form-control" id="inputGroupFile01" accept=".png, .jpg, .jpeg"/>
                                        </div>


                                        <div className="mb-3" style={{display: "none"}}>
                                            <label className="form-label">Name</label>
                                            <input type="text" name="name" className="form-control" placeholder="Name"/>
                                        </div>

                                        <div className="mb-3" style={{display: "none"}}>
                                            <label className="form-label">Date</label>
                                            <input type="text" name="date" className="form-control" placeholder="dd-mm-yyyy"/>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Number <span className="text-danger">*</span></label>
                                            <input type="number" name="qt" className="form-control" placeholder="Images range 1 to 30" min="1" max="42" required/>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Page Size <span className="text-danger">*</span></label>
                                            <select className="form-select js-choice" name="page" required>
                                                <option value="4x6-3x4">4x6 page (12 photos)</option>
                                                <option value="4x6-4x2">4x6 page (8 photos)</option>
                                                <option value="a4-5x6">A4-5x6 page (30 photos)</option>
                                                <option value="a4-6x7">A4-6x7 page (42 photos)</option>
                                            </select>
                                        </div>


                                        <div className="mb-3">
                                            <label className="form-label">Change Background</label>
                                            <select id="" className="form-select js-choice" name="cb" required>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label">Expand Image (Recommend No)</label>
                                            <select id="" className="form-select js-choice" name="expand" required>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>

                                        <div className="mb-3 col-sm-6 col-md-4">
                                            <label className="form-label">Background Color</label>
                                            <input type="color" className="form-control" name="bg" placeholder="Select color" style={{height: "45px"}} defaultValue="#ffffff"/>
                                        </div>
                                    </div>


                                    <div className="card-footer border-top">
                                        <div className="text-center mt-3">
                                            <button className="btn btn-primary fs-5">
                                                <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                                <span role="status">Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>

                        
                        {/* <!-- Card body END --> */}
                    </div>
                </div>



                <PublicDashboardIframe src={output.a4}/>


                <div className="col-12">
                    <AdsComponent dataAdSlot="5824067931" />
                </div>
            </div>

        </>
    )
}

export default PublicDashboardPassporSizePhoto;