// import { Link } from "react-router-dom";


import Form from 'react-bootstrap/Form';
import AuthContext from '../../../../context/auth';
import { TypeCardValue } from '../../../../interfaces/Interface';
import { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';


function PublicAPI() {
    const { states, fn } = useContext(AuthContext);
    const [selectedState, setSelectedState] = useState<string | null>(null);
    const [selectedCard, setSelectedCard] = useState<TypeCardValue | null>(null);

    return (

        <section className="pt-4 pt-lg-5">
            <div className="container">
                {/* Title */}
                <div className="row">
                    <div className="col-12 text-center mb-4">
                        <h6>API Documentation</h6>
                        <h1 className="fs-2 mb-0">API Documentation - Public</h1>
                        <p>Last updated: May 21, 2024</p>
                    </div>
                </div>

                {/* Content START */}
                <div className="row">
                    <div className="col-md-8 mx-auto">
                        <div className="vstack gap-3">

                            <h4>Overview</h4>
                            <p>Our API is organized around REST. Our API has predictable resource-oriented URLs, accepts form-encoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs.</p>

                            <h4>Authentication</h4>
                            <p>Authentication is done via Authroization header. You need to pass your auth key as the value of the Authorization header. You can get your API key from your account settings.</p>

                            <h4>Base URL</h4>
                            <div><span className='p-2 bg-light text-danger fw-semibold border rounded-1'>https://api.idcard.store</span></div>

                            <Card className="my-4">
                                <Card.Header className="m-0 pb-0">
                                    <h5>Select State and Card</h5>
                                </Card.Header>
                                <Card.Body>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Select State</Form.Label>
                                        <Form.Select aria-label="Select State" onChange={(e) => setSelectedState(e.target.value)}>
                                            <option value="">Select State</option>
                                            {states.map((state, index) => (
                                                <option key={index} value={state.shortName()}>{state.name}</option>
                                            ))}
                                        </Form.Select>

                                        {selectedState &&
                                            <Form.Group className="my-3">
                                                <Form.Label>Select Card</Form.Label>
                                                <Form.Select aria-label="Select Card" onChange={(e) => {
                                                    if (e.target.value) {
                                                        setSelectedCard(fn.getCardPageSettings(e.target.value))
                                                    } else {   // Reset the selected card
                                                        setSelectedCard(null)
                                                    }
                                                }}>
                                                    <option value="">Select Card</option>
                                                    {
                                                        Object.entries(fn.cardsForState(selectedState)).map(([key, value]) => {
                                                            return <option key={key} value={value.path}>{`${value.paid ? 'Paid - ' : 'Free - '} ${value.name}`}</option>
                                                        })
                                                    }
                                                    {
                                                        Object.entries(fn.allFree()).map(([key, value]) => {
                                                            return <option key={key} value={value.path}>{`${value.paid ? 'Paid - ' : 'Free - '} ${value.name}`}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        }

                                    </Form.Group>

                                </Card.Body>
                            </Card>

                            {/* Show Endpoint */}
                            {
                                selectedCard && <div className="mb-3">

                                    {/* Header */}
                                    <div className="my-3">
                                        <div className="mb-3 d-flex align-items-center fs-4 fw-bold">
                                            <kbd className="px-2 me-2 align-center me-3">{selectedCard.method}</kbd>
                                            <p className="mb-0">{selectedCard.endpoint}</p>
                                        </div>
                                        <div>
                                            <span className="badge bg-primary me-2">{selectedCard.paid ? "Paid" : "Free"}</span>
                                            <span className="badge bg-primary">{selectedCard.state === "all" ? "All States" : fn.fullStateName(selectedCard.state)}</span>
                                        </div>
                                    </div>

                                    {/* parameters */}
                                    <div className="parameters my-3">
                                        <h5 className='mb-0'>Body</h5>
                                        <p className='mt-0'>The request body should be in multipart/form-data format.</p>

                                        <ul>
                                            {
                                                selectedCard.page_settings.input.map((input, index) => {
                                                    if (input.type === 'select') {
                                                        return <li key={index}><kbd className="px-2 me-2 align-center">{input.name}</kbd> <span className='fw-bold'>({input.type})</span>: {input.label || "NA"}
                                                            <ul>
                                                                {
                                                                    input.options.map((option, index) => {
                                                                        return <li key={index}><kbd className="px-2 me-2 align-center">{option.value}</kbd>: {option.key}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </li>
                                                    }
                                                    return <li key={index}><kbd className="px-2 me-2 align-center">{input.name}</kbd> <span className='fw-bold'>({input.type})</span>: {input.label || "NA"}</li>
                                                })
                                            }
                                        </ul>
                                    </div>

                                    {/* headers */}
                                    <div className='headers my-3'>
                                        <h5>Headers</h5>
                                        <ul>
                                            <li><kbd className="px-2 me-2 align-center">Authorization</kbd>: Bearer YOUR_API_KEY</li>
                                            <li><kbd className="px-2 me-2 align-center">User-Agent</kbd>: YOUR_APP_NAME</li>
                                        </ul>
                                    </div>


                                    {/* Response */}

                                    <div className='request my-3'>
                                        <h5 className='mb-0'>Response</h5>
                                        <p className='mt-0'>Response will be in JSON format.</p>
                                        <ul>
                                            {
                                                selectedCard.page_settings.output.pvc && <>
                                                    <li><kbd className="px-2 me-2 align-center">front</kbd> <span className='fw-bold'>(string)</span>: Front Image file in base64 encoded format</li>
                                                    <li><kbd className="px-2 me-2 align-center">back</kbd> <span className='fw-bold'>(string)</span>: Back Image file in base64 encoded format</li>
                                                </>
                                            }
                                            {
                                                selectedCard.page_settings.output.iframe && <li><kbd className="px-2 me-2 align-center">a4</kbd> <span className='fw-bold'>(string)</span>: A4 PDF file in base64 encoded format.</li>
                                            }
                                            {
                                                selectedCard.page_settings.output.iframe_image && <li><kbd className="px-2 me-2 align-center">a4_img</kbd> <span className='fw-bold'>(string)</span>: A4 Image file in base64 encoded format.</li>
                                            }
                                            {
                                                selectedCard.page_settings.output.iframe_html && <li><kbd className="px-2 me-2 align-center">html</kbd> <span className='fw-bold'>(string)</span>: HTML file in base64 encoded format.</li>
                                            }

                                        </ul>
                                    </div>


                                    {/* Errors */}
                                    <div className='error my-3'>
                                        <h5>Status Codes</h5>
                                        <ul>
                                            <li><kbd className="px-2 me-2 align-center">400</kbd>: The request was malformed or missing required parameters.</li>
                                            <li><kbd className="px-2 me-2 align-center">401</kbd>: The API key provided was invalid or missing.</li>
                                            <li><kbd className="px-2 me-2 align-center">403</kbd>: The request was authenticated but the user does not have the necessary permissions.</li>
                                            <li><kbd className="px-2 me-2 align-center">404</kbd>: The requested resource was not found.</li>
                                            <li><kbd className="px-2 me-2 align-center">500</kbd>: An unexpected error occurred on the server.</li>
                                        </ul>
                                    </div>
                                </div>
                            }

                            {/* high lighted note */}
                            <div className="bg-light border-left border-primary p-3 text-danger mt-4 fs-6">
                                <p className="mb-0">Note: While using the service you agree to our <a href="/terms" className="text-danger fw-bold text-primary-hover">Terms of Service</a>,  <a href="/privacy" className="text-danger fw-bold text-primary-hover">Privacy Policy</a>, <a href="/refund" className="text-danger fw-bold text-primary-hover">Refund Policy</a> and <a href="/how-we-make-cards" className="text-danger fw-bold text-primary-hover">How we make cards</a>.
                                    We will not be responsible for any misuse of the service. Please use the service responsibly, and do not use it for any illegal activities. We reserve the right to block any user from using the service if we find any misuse of the service.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
                {/* Content END */}
            </div>
        </section>


    );
}


export default PublicAPI;