import PublicDashboardTitle from "../Components/Title";
import { useState, useEffect, useRef } from "react";
import Branding from "../../../../Branding/Branding";

import {useMutationSendForm} from "../../../../api/formdata";
import {PublicDashboardIframe} from "../Components/PageElements";

import { getOutputState } from "../../../../helper/Main";
import { TypeOutput } from "../../../../api/types";

import {convertBase64ToURL} from "../../../../helper/Decoder";
import AdsComponent from "../../../../components/GoogleAds/GoogleAds";

function AddQualificationRow({deleteBtn}) {
    function rem(e) {
        // console.log(e)
        // console.log(e.target.parentElement.parentElement)
        if (e.target.parentElement.parentElement.classList.contains("row")){
            e.target.parentElement.parentElement.remove();
        } else {
            e.target.parentElement.parentElement.parentElement.remove();
        }
        
    }
    return (
        <div className="row g-1">
            {
                ["Exam", "Board/University", "Passing year", "Marks %", "Division"].map((item, index) => {
                    return (
                        <div className="mb-3 col" key={index}>
                            <input type="text" name={item.toLocaleLowerCase()} placeholder={item} className="form-control" required/>
                        </div>
                    )
                })
            }
            {deleteBtn > 0 && 
                <div className="mb-3 col-1">
                    <button type="button" className="form-control btn btn-danger" onClick={rem}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                </div>
            }
        </div>
    )
}

type typePayload  = { 
    personal_info: {
        name: string,
        phone: string,
        email: string,
        address: string,
        dob: string,
        father: string,
        mother: string,
        nationality: string,
        maritial_status: string,
        language: string,
        hobbies: string,
    },
    type: string,
    career_object: string[],
    academic_qualification: string[][],
    professonal_qualification: string[][],
    extra_qualification: string[],
    work_experience: string[]
}


function ResumeForm() {
    const [payload, setPayload] = useState<typePayload>({type: "Resume"} as typePayload)
    const [photo, setPhoto] = useState<File>()
    const [output, setOutput] = getOutputState();

    const academicQualificationRef  = useRef() as React.MutableRefObject<HTMLDivElement>;
    const professonalQualificationRef  = useRef() as React.MutableRefObject<HTMLDivElement>;

    const [qua, setQua] = useState([{id: 0}]);
    const [pro, setPro] = useState([{id: 0}]);

    const branding = Branding();

    const {mutate, isLoading, data} = useMutationSendForm()

    function add(selector:string) {
        if (selector === "#aca_q") {
            setQua(m => [...m, {id: qua.length}])
            return
        }
        if (selector === "#pro_q") {
            setPro(m => [...m, {id: pro.length}])
            return
        }
    }

    function updateQualifications() {
        let academic_qualification:string[][] = []
        let professonal_qualification:string[][] = []

        let rows = academicQualificationRef.current.querySelectorAll(".row")
        for (let n of Array(...rows)) {
            if (n.hasAttribute("style")) {continue}

            let inputs = n.querySelectorAll("input");
            academic_qualification.push(Array(...inputs).map((item) => item.value))
        }

        rows = professonalQualificationRef.current.querySelectorAll(".row")
        for (let n of Array(...rows)) {
            if (n.hasAttribute("style")) {continue}

            let inputs = n.querySelectorAll("input");
            professonal_qualification.push(Array(...inputs).map((item) => item.value))
        }

        setPayload(m => ({...m, academic_qualification, professonal_qualification}))

        return {academic_qualification, professonal_qualification}
    }

    function onChangeBasic(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.name) {
            if (e.target.type === "file" && e.target.files) {
                setPhoto(e.target.files[0])
            } else if (["career_object"].includes(e.target.name)) {
                setPayload(m => ({...m, [e.target.name]: [e.target.value]}))
            } else if (e.target.name == "type") {
                setPayload(m => ({...m, [e.target.name]: e.target.value}))
            } else {
                setPayload(m => ({...m, 
                    "personal_info": {...m["personal_info"], [e.target.name]: e.target.value}}
                ))
            }
            
        }
    }

    function onChangeSpliter(e : React.ChangeEvent<HTMLInputElement>) {
        if (e.target.name) {
            setPayload(m => ({...m, [e.target.name]: e.target.value.split(",")}))
        }
    }

    function onsubmit(e : React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const {academic_qualification, professonal_qualification} = updateQualifications()

        const c_payload = {...payload, academic_qualification, professonal_qualification}

        let formdata = new FormData();
        formdata.append("photo", (photo as File) || "")
        formdata.append("data", JSON.stringify(c_payload))

        mutate({
            endpoint: "/other/make_resume",
            form: formdata
        })
    }

    useEffect(() => {
        if (data) {
            setOutput(convertBase64ToURL(data) as TypeOutput);
            console.log(convertBase64ToURL(data));
        }
    }, [data])

  
    return (
        <>
            <div className="col-12">
                <div className="card border">
                    <div className="card-header align-items-center border-bottom p-4">

                        <h3 className="mb-0 text-center">Make Resume {!branding.refadmin && "@2 /-"}</h3>

                    </div>
                    {/* <!-- Card body START --> */}
                    <div className="card-body">

                        <form onSubmit={onsubmit}>
                            <div onChange={onChangeBasic}>
                                <div className="row g-3">
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label">Type <span className="text-danger">*</span></label>
                                        <select className="form-select" name="type" defaultValue={payload.type} required>
                                            <option value="Resume">Resume</option>
                                            <option value="Curriculum Vitae">Curriculum Vitae</option>
                                        </select>
                                    </div>
                                    {/* input type file */}
                                    <div className="mb-3 col-md-6">
                                        <label className="form-label">Photo</label>
                                        <input type="file" name="photo" className="form-control"/>
                                    </div>
                                </div>
                                {
                                    [["Name", "Phone", "Email"], ["Address", "DOB", "Father"], ["Mother", "Nationality", "Maritial_Status"], ["Language", "Hobbies"]].map((item, index) => {
                                        return (
                                            <div className="row" key={index}>
                                                {
                                                    item.map((subitem, subIndex) => {
                                                        return (
                                                            <div className="mb-3 col-md-4" key={subIndex}>
                                                                <label className="form-label">{subitem} <span className="text-danger">*</span></label>
                                                                <input type="text" name={subitem.toLocaleLowerCase()} placeholder={subitem} className="form-control" required/>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            <div className="row g-3" onChange={onChangeBasic}>
                                <div className="mb-3 col">
                                    <label className="form-label">Career Object <span className="text-danger">*</span></label>
                                    <select className="form-select" name="career_object" size={4} required>
                                        {
                                            [
                                            "Secure a responsible career opportunity to fully utilize my talent and skills to grow, while making a significant contribution to the success of the company.", 
                                            "Self-motivated and hardworking fresher seeking for an opportunity to work in a challenging environment to prove my skills and utilize my knowledge & intelligence in the growth of the organization.",
                                            "Urge for a position in an aggressively growing organization where my technical & functional expertise can be effectively utilized and possess good analytical abilities, quick grasping power, zeal for learning new things and excellent communication skills.",
                                            "To seek a good & responsible job in professionally managed organization where in my conceptual and functional skills are effectively utilized in a way that contributes to the organization growth coupled with personal growth within the organization.",
                                            "To make a good position in a reputed company and work enthusiastically in team which provides steady career growth along with job satisfaction, challenges and give value contribution in the success and to build a challenging career with honesty and loyalty by translating my experience knowledge ,skills and abilities into value for an organization.",
                                            "To contribute my best to the organization irrespective of the kind of project undertaken and to utilize my skills and to perform the job to the best of my ability with the zeal and willing to learn."
                                            ].map((item, index) => {
                                                return (
                                                    <option value={item} key={index}>{item}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            
                            <div className="row g-3" id="aca_q" ref={academicQualificationRef}>
                                <h5>Academic Qualification <span className="text-danger">*</span></h5>

                                {
                                    qua.map((_, id) => {
                                        return (
                                            <AddQualificationRow deleteBtn={id} key={id}/>
                                        )
                                    })
                                }

                            </div>

                            <div className="col-12 mb-3">
                                <button className="btn btn-primary" type="button" onClick={() => add("#aca_q")}>Add <i className="fa-solid fa-plus"></i></button>
                            </div>

                            <div className="row g-3" id="pro_q" ref={professonalQualificationRef}>
                                <h5>Professonal Qualification <span className="text-danger">*</span></h5>

                                {
                                    pro.map((_, id) => {
                                        return (
                                            <AddQualificationRow deleteBtn={id} key={id}/>
                                        )
                                    })
                                }

                            </div>

                            <div className="col-12 mb-3">
                                <button className="btn btn-primary" type="button" onClick={() => add("#pro_q")}>Add <i className="fa-solid fa-plus"></i></button>
                            </div>

                            <div className="row g-3" onChange={onChangeSpliter}>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Extra Qualification | seprated by coma <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" name="extra_qualification" placeholder="Extra Qualification | seprated by coma" required/>
                                </div>
                                {/* input type file */}
                                <div className="mb-3 col-12">
                                    <label className="form-label">Work Experience | seprated by coma <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" name="work_experience" placeholder="Work Experience | seprated by coma" required/>
                                </div>
                            </div>



                            <div className="text-center mt-3">
                                <button className="btn btn-primary fs-5">
                                    <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{display: !isLoading? 'None': ""}}></span>
                                    <span role="status">Submit</span>
                                </button>
                            </div>

                        </form>

                    </div>
                    {/* <!-- Card body END --> */}
                </div>
            </div>

            <PublicDashboardIframe src={output.a4}/>
        </>
    )
}


function PublicDashboardResumeMaker() {

    return (
        <>
            <PublicDashboardTitle title={"Resume Maker"} icon={"fa-solid fa-file-lines fa-fw me-1"} addcardbtn={false} />

            <div className="row g-4">
                <ResumeForm />
                <div className="col-12">
                    <AdsComponent dataAdSlot="5824067931" />
                </div>
            </div>

        </>
    )
}

export default PublicDashboardResumeMaker;