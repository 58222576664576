import { useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { download_img, download_canvas, download_canvas_pdf } from "../../../../helper/Downloader";

type customProps = {
    src?: string,
    filename?: string
    title?: string,
    description?: string
    innerRef?: any
    filename_pdf?: string

    onClickFn?: any
    beforeDownloadFn?: any
}

function PublicDashboardIframe(props: customProps) {
    const { src=import.meta.env.VITE_CDN_URL + "/idmaker/files/placeholder.pdf", 
            filename="somefile.pdf", title="A4 size page.", 
            description="This is A4 size page. This page might not work for PVC card." } = props;

    function download() {
        let urlElement: Element | null = document.querySelector("iframe");

        if (urlElement) {
            let url: string | null = urlElement.getAttribute("src");
            if (!url) return;
            download_img(url, filename);
        }
    }


    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small">{description}</p>
                </div>

                <div className="card-body ratio ratio-16x9">
                    <iframe src={src}></iframe>
                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn" type="button" onClick={download}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status">Download</span>
                        </button>
                    </div>
                </div>


            </div>
        </div>
    );
}

function PublicDashboardIframeImage(props: customProps) {
    const { src=import.meta.env.VITE_CDN_URL + "/idmaker/files/card_front.png",
            filename="somefile.png", title="A4 size Image.",
            description="This is A4 size Image.", onClickFn } = props;
        
    const download = () => {
        download_img(src, filename);
    }

    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small"> {description} </p>
                </div>

                <div className="card-body">
                    <img src={src} crossOrigin="anonymous" />
                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn" type="button" onClick={onClickFn || download}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status">Download</span>
                        </button>
                    </div>
                </div>


            </div>
        </div>
    );
}


function PublicDashboardIframeHtml(props: customProps) {
    const { src=import.meta.env.VITE_CDN_URL + "/idmaker/files/placeholder.pdf",
            title="HTML Page Preview", description="Easily print this page with print button." } = props;

    const refIframe = useRef() as React.MutableRefObject<HTMLIFrameElement>;

    const doPrint = () => {
        refIframe.current.contentWindow?.print();
    }


    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small"> {description} </p>
                </div>

                <div className="card-body ratio ratio-16x9">
                    {/* @ts-ignore */}
                    <iframe src={src} ref={refIframe}></iframe>
                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn" type="button" onClick={doPrint}>
                            <span role="status">Print</span>
                        </button>
                    </div>
                </div>


            </div>
        </div>
    );
}

function PublicDashboardIframeCanvas(props: customProps) {
    const { filename="somefile.png", filename_pdf="somefile.pdf", title="A4 size Image.",
    description="This is A4 size Image.", beforeDownloadFn, innerRef } = props;

    async function check(): Promise<boolean> {
        if (!beforeDownloadFn) {
            return true;
        }
        if (beforeDownloadFn.constructor.name === "AsyncFunction") {
            let result = await beforeDownloadFn();
            return result;
        } else {
            return beforeDownloadFn();
        }
    }
    async function download_img() {
        const resp = await check();
        if (resp) {
            download_canvas(innerRef, filename);
        }

    }

    async function download_pdf() {
        if (!filename_pdf) {
            return;
        }
        const resp = await check();
        if (resp) {
            download_canvas_pdf(innerRef, filename_pdf);
        }
    }

    useEffect(() => {
        // disable right click 
        innerRef.current.addEventListener("contextmenu", (e) => {
            e.preventDefault();
        })
    }, [])


    return (
        <div className="col">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">{title}</h5>
                    <p className="mb-0 small"> {description} </p>
                </div>

                <div className="card-body">
                    <canvas ref={innerRef} style={{ background: "white", width: "100%" }}></canvas>
                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 iframe-download-btn me-3" type="button" onClick={download_img}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status"> Image</span>
                        </button>
                        <button className="btn btn-primary fs-5 iframe-download-btn ms-3" type="button" onClick={download_pdf}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status"> pdf</span>
                        </button>
                    </div>
                </div>



            </div>
        </div>
    );
}


function PublicDashboardPvcCardDisplay({ src_front=import.meta.env.VITE_CDN_URL + "/idmaker/files/card_front.png", 
            src_back=import.meta.env.VITE_CDN_URL + "/idmaker/files/card_back.png" }: { src_front?: string, src_back?: string }) {

    const download = () => {
        let urlElement: Element | null = document.querySelector(".carousel-item.active img");
        let filenameElement: Element | null = document.querySelector(".carousel-item.active img");

        if (urlElement && filenameElement) {
            let url: string | null = urlElement.getAttribute("src");
            let filename: string | null = filenameElement.getAttribute("filename");

            if (url && filename) {
                download_img(url, filename);
            }
        }
    }


    return (
        <div className="col-md-6">
            <div className="card border">
                <div className="card-header border-bottom">
                    <h5 className="card-header-title">Front and Back</h5>
                    <p className="mb-0 small">Please click on the &lt; and &gt;  to slide the images.</p>
                </div>
                <div className="card-body p-0">

                    <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                {/* @ts-ignore */}
                                <img filename="front.png" src={src_front} className="d-block w-100" alt="..." />
                            </div>
                            <div className="carousel-item">
                                {/* @ts-ignore */}
                                <img filename="back.png" src={src_back} className="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                            <span className="" aria-hidden="true" > <i className="fa-regular fa-circle-left fa-beat fa-2xl" style={{ color: "#1d3359" }}></i> </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                            <span className="" aria-hidden="true" > <i className="fa-regular fa-circle-right fa-beat fa-2xl" style={{ color: "#1d3359" }}></i> </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>


                <div className="card-footer border-top">
                    <div className="text-center mt-3">
                        <button className="btn btn-primary fs-5 download-btn" type="button" onClick={download}>
                            <span className="fa fa-download me-2" aria-hidden="true"></span>
                            <span role="status">Download</span>
                        </button>
                    </div>
                </div>


            </div>

        </div>
    )
}

// ===========================================================================================================

function PublicDashboardCardPageInputFile(props) {
    const { name, accept, required, label } = props;

    return (
        <div className="input-group mb-3">
            <label className="input-group-text d-none d-sm-block text-capitalize" htmlFor="inputGroupFile01">Select {label || 'File'} {required && <span className="text-danger">*</span>}</label>
            <input type="file" name={name} className="form-control" id="inputGroupFile01" accept={accept} required={required} />
        </div>
    )
}
PublicDashboardCardPageInputFile.propTypes = {
    name: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    required: PropTypes.bool,
    label: PropTypes.string.isRequired
}

function PublicDashboardCardPageInputText(props) {
    const { name, placeholder, required, label } = props;

    return (
        <div className="mb-3">
            <label className="form-label">{label} {required && <span className="text-danger">*</span>} </label>
            <input type="text" name={name} className="form-control" placeholder={placeholder} required={required} />
        </div>
    )
}
PublicDashboardCardPageInputText.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired
}

function PublicDashboardCardPageSelect(props) {
    const { name, required, label, options, value, extraClass="", visible=true } = props;

    // console.log("options", options)

    return (
        <div className={"mb-3 " + extraClass} style={{ display: !visible ? 'None' : "" }}>
            <label className="form-label">{label} {required && <span className="text-danger">*</span>} </label>
            <select className="form-select" name={name} required={required} defaultValue={value} >
                {options.map((option, index) => {
                    // console.log(option)
                    return (
                        <option key={index} value={option.value}>{option.key}</option>
                    )
                })}
            </select>
        </div>
    )
}

PublicDashboardCardPageSelect.propTypes = {
    name: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    extraClass: PropTypes.string,
    visible: PropTypes.bool,
}

export {
    PublicDashboardIframe, PublicDashboardPvcCardDisplay,
    PublicDashboardCardPageInputFile, PublicDashboardCardPageInputText,
    PublicDashboardCardPageSelect, PublicDashboardIframeImage, PublicDashboardIframeCanvas,
    PublicDashboardIframeHtml
}