// import { useParams } from 'react-router-dom';
import PublicDashboardTitle from "../Components/Title";
import AuthContext from '../../../../context/auth';
import { useContext, useEffect } from 'react';
import AdsComponent from '../../../../components/GoogleAds/GoogleAds';

import { CardNotification } from "../../../../components/Alert/Alert";

import {
    PublicDashboardIframe, PublicDashboardPvcCardDisplay,
    PublicDashboardCardPageInputFile, PublicDashboardCardPageInputText,
    PublicDashboardCardPageSelect, PublicDashboardIframeImage, PublicDashboardIframeHtml
} from "../Components/PageElements";
import { useMutationSendForm } from "../../../../api/formdata";
import { convertBase64ToURL } from "../../../../helper/Decoder";
import { getOutputState } from "../../../../helper/Main";
import { TypeOutput } from "../../../../api/types";
import queryClient from "../../../../context/query";

import { ConfirmModal } from "../../../../helper/Modal";
import { useState } from "react";

function PublicDashboardAllCards() {
    const [output, setOutput] = getOutputState()
    const [show, setShow] = useState(false);

    // state for formdata
    const [formData, setFormData] = useState(new FormData());

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);


    // const cardName = window.location.pathname.split("/").slice(-1)[0];
    const { fn } = useContext(AuthContext);
    const cardPayload = fn.getCardPageSettings(window.location.pathname);
    const settings = cardPayload.page_settings;

    let form: JSX.Element[] = [];
    let count = 0;
    for (let n of settings.input) {
        if (n.type === "file") {
            form.push(<PublicDashboardCardPageInputFile key={count} name={n.name} accept={n.accept} required={n.required} label={n.label || ""} />);
        }
        if (n.type === "text") {
            form.push(<PublicDashboardCardPageInputText key={count} name={n.name} required={n.required} placeholder={n.placeholder} label={n.label} />);
        }
        if (n.type === "select") {
            form.push(<PublicDashboardCardPageSelect key={count} name={n.name} required={n.required} label={n.label} options={n.options} value={n.value} extraClass={n.extraClass} visible={n.visible} />);
        }
        count++;
    }

    const { mutateAsync, isLoading, data } = useMutationSendForm();

    useEffect(() => {
        if (data) {
            setOutput(convertBase64ToURL(data) as TypeOutput);
        }
    }, [data])


    async function onAccept() {
        // make sure form is not empty  
        if (formData.entries().next().done) {
            return;
        }

        // remove empty file inputs
        for (const [key, value] of Array(...formData.entries())) {
            if (value instanceof File && !value.name) {
                formData.delete(key);
            }
        }

        await mutateAsync({
            endpoint: cardPayload.endpoint,
            form: formData
        })
        queryClient.invalidateQueries("userStats");
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        // get form data
        const formData = new FormData(e.target as HTMLFormElement);
        // set form data
        setFormData(formData);

        modalShow();
    }



    return (
        <>

            <PublicDashboardTitle title={"Make " + cardPayload.name + " (Cards)"} icon={""} addcardbtn={false} />

            {
                cardPayload.notification && 
                <CardNotification title={cardPayload.notification.title} message={cardPayload.notification.message} sample_files={cardPayload.notification.sample_files} />
            }

            <ConfirmModal show={show} onClose={modalClose} onAccept={onAccept} onDecline={modalClose} />


            <div className="row g-4">

                <div className="col-md-6">
                    <div className="card border">
                        <div className="card-header border-bottom">
                            <h5 className="card-header-title title-">{"Make " + cardPayload.name + " (Cards)"}</h5>
                            <p className="mb-0 small"> {cardPayload.description || `${cardPayload.name} is Indian Identity card.`} </p>
                        </div>
                        {/* <!-- Card body START --> */}
                        <form method='POST' onSubmit={onSubmit}>
                            <div className="card-body">

                                { form }


                            </div>

                            <div className="card-footer border-top">

                                <div className="text-center mt-3">
                                    <button className="btn btn-primary fs-5" type="submit" value="Submit" disabled={isLoading}>
                                        <span className="spinner-border spinner-border-sm me-2" aria-hidden="true" style={{ display: !isLoading ? 'None' : "" }}></span>
                                        <span role="status">Submit</span>
                                    </button>


                                    {/* <button className="btn btn-secondary ms-2 fs-5" type="button" onClick={modalShow}>
                                        <span role="status">Reset</span>
                                    </button> */}
                                </div>


                            </div>

                        </form>
                        {/* <!-- Card body END --> */}
                    </div>
                </div>

                {settings.output.pvc && <PublicDashboardPvcCardDisplay src_back={output.back} src_front={output.front} />}
                {settings.output.iframe && <PublicDashboardIframe src={output.a4} />}
                {settings.output.iframe_image && <PublicDashboardIframeImage src={output.a4_img} />}
                {settings.output.iframe_html && <PublicDashboardIframeHtml src={output.html} />}

                <div className="col-12">
                    <AdsComponent dataAdSlot="5824067931" />
                </div>

            </div>

        </>
    )
}



export default PublicDashboardAllCards;