import { useState, useEffect } from 'react';
// import useHttpClient from './useHttpClient';

import { TypeCards, TypeStates, TypeCardValue} from "../interfaces/Interface";
import { typeUser as User } from "../api/types";
import axiosClient from './useAxios';
// import axios from 'axios';

import cardsFile from '../json/cards.json';
import responseData from '../json/states.json';


function useAuth() {
    const [token, setToken] = useState("");
    const [user, setUser] = useState<User>({} as User);
    const [isLogin, setIsLogin] = useState(false);
    const [isReady, setIsReady] = useState(false);
    // const [states, setStates] = useState<TypeStates[]>([]);
    // const [cards, setCards] = useState<TypeCards>({});

    const fn = {
        "sort": ( a: any, b: any) => {
            let aa = a.name.toLowerCase();
            let bb = b.name.toLowerCase();
        
            return aa < bb ? -1 : aa > bb ? 1 : 0;
        },

        "fullStateName": (sc:string) => {
            for (let n of states) {
                if (n.shortName() === sc) {
                    return n.name;
                }
            }
            return "";
        },

        "cardsForState": (sc:string) => {
            let cards_tmp:TypeCardValue[] = [];
            for (const [_, value] of Object.entries(cards)) {
                if (value && ((value.state == sc || value.state == "all") && (value.visible && !value.disabled && value.paid))) {
                    cards_tmp.push(value);
                }
            }
            return cards_tmp.sort(fn.sort);
        },

        "getCardPageSettings": (sc:string) => {
            for (const [_, value] of Object.entries(cards)) {
                if (value.path.toLowerCase() == sc.toLowerCase()) {
                    return value
                }
            }
            return {} as TypeCardValue;
        },

        "allFree": () => {
            let cards_tmp:TypeCardValue[] = [];
            for (const [_, value] of Object.entries(cards)) {
                if (value && (value.visible && !value.disabled && !value.paid)) {
                    cards_tmp.push(value);
                }
            }
            return cards_tmp.sort(fn.sort);
        }

    }


    let states_tmp:TypeStates[] = [];
    for (let n of responseData) {
        states_tmp.push({
            name: n.name,
            icon: n.icon,
            shortName: () => {
                return n.icon.split("/").slice(-1)[0].split(".")[0].toLowerCase()
            }
        });
    }
    
    
    // @ts-ignore 
    const cards = cardsFile as TypeCards;
    const states = states_tmp.sort(fn.sort) as TypeStates[]


    const logout = () => {
        setIsReady(false);
        axiosClient.post("/logout", {}).then((res) => {
            console.log(res.data);
        }).finally(() => {
            localStorage.removeItem("token");
            setToken("");
            setIsLogin(false);
            setIsReady(true);
        })
    }

    const login = () => {
        axiosClient.post("/login", {}).then((res) => {
            // console.log(res.data);
            setUser(res.data);
            setIsLogin(true);
        }).catch((err) => {
            console.log(err);
            window.localStorage.removeItem("token");
        }).finally(() => {
            setIsReady(true);
        })
    }

    const refreshUser = login

    const fetchUser= async () => {
        const _token = localStorage.getItem("token");
        if (_token) {
            setToken(_token);
        }
        return _token;
    };

    // const fetchStatesDate = async () => {
    //     try {
    //         const req = await axios.get(`/json/states.json`);
    //         const responseData = req.data;
    //         let states_tmp:TypeStates[] = [];
    
    //         for (let n of responseData) {
    //             states_tmp.push({
    //                 name: n.name,
    //                 icon: n.icon,
    //                 shortName: () => {
    //                     return n.icon.split("/").slice(-1)[0].split(".")[0].toLowerCase()
    //                 }
    //             });
    //         }
    
    //         setStates(states_tmp.sort(fn.sort));
    //     } catch (err) {console.log(err);}
    // };
    
    // const fetchCardsDate = async () => {
    //     try {
    //         const req = await axios.get(`/json/cards.json`);
    //         const responseData = req.data;
    
    //         // console.log(responseData);
    //         setCards(responseData);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };

    useEffect(() => {
        if (token == "") return;
        localStorage.setItem("token", token);
        setIsReady(false);
        // console.log("token", token)
        // console.log("useEffect Login started")
        login();
        // console.log("useEffect Login end")
    }, [token])


    useEffect(() => {
        (async function() {
            const token = await fetchUser();
            if (!token) {
                setIsReady(true);
            }

            // await Promise.all([fetchStatesDate()]);
            
        })();
    }, []);

    return {token, states, fn, isReady, cards, setToken, isLogin, user, logout, refreshUser, setUser }
}

export default useAuth;

