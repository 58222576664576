import {useState} from "react";
import {TypeOutput} from "../api/types";

function handleSubmit(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();


    console.log("Submitted");
}


function getOutputState() {
    return useState<TypeOutput>({
        front: undefined,
        back: undefined,
        a4: undefined,
        a4_img: undefined,
        sample: undefined,
        html: undefined,
    });
}



export { handleSubmit, getOutputState }