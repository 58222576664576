import GetConfetti from "../../helper/Confetti";
import { useCallback } from "react";

function Diwali() {
    const { Firework } = GetConfetti();
    const doFirework = useCallback(() => {
        Firework();
    }, []);


    return (
        <div className="bg-light rounded-3 pt-4 mb-3 border" role="alert">
            <div className="d-flex justify-content-between">
                <img src="https://mayankfawkes.blr1.digitaloceanspaces.com/kumbh-kalash.gif" className="w-80px" alt="" />
                <img src="https://mayankfawkes.blr1.digitaloceanspaces.com/kumbh-kalash.gif" className="w-80px" alt="" />
            </div>

            <div className="d-flex justify-content-center" style={{ marginTop: "-50px" }}>
                <div className="d-flex align-items-center flex-sm-row flex-column mb-3">
                    <img src="https://mayankfawkes.blr1.digitaloceanspaces.com/diwali.gif" className="w-80px me-3 mb-2 mb-sm-0" alt="" style={{ cursor: "pointer" }} onClick={doFirework} />
                    <h3 className="mb-0">Happy Diwali 2024</h3>
                </div>
            </div>
            <div className="d-flex justify-content-center mx-3">
                <p className="text-muted" style={{ textAlign: "justify", textJustify: "inter-word" }}>Wishing you a very happy and prosperous Diwali. May this festival of lights bring you joy, happiness, and prosperity.</p>
            </div>

            <div className="d-flex justify-content-center mx-3 mb-0 pb-0">
                <p className="small mb-1" style={{ textAlign: "justify", textJustify: "inter-word" }}>(Click on Diya for firework)</p>
            </div>
            
        </div>
    );
}

export { Diwali };